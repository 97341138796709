// Import Chart.js
import { Chart, Filler, LinearScale, LineController, LineElement, PointElement, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { tailwindConfig, formatValue, hexToRGB } from '../utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

// Utility function to fill missing weeks with default values
const fillMissingWeeks = (weeklySales) => {
    const allWeeks = [1, 2, 3, 4, 5]; // Define expected weeks

    // Find the `order_created_at` of the first existing week
    const firstValidEntry = weeklySales.find((entry) => entry.order_created_at);
    if (!firstValidEntry) throw new Error("No valid `order_created_at` found in weeklySales.");

    // Extract year and month from the first valid `order_created_at`
    const firstDate = new Date(firstValidEntry.order_created_at);
    const year = firstDate.getFullYear();
    const month = firstDate.getMonth(); // 0-indexed (0 = January, 1 = February, ...)

    // Map existing weeks to their data
    const weekMap = weeklySales.reduce((map, entry) => {
        map[entry.week_of_month] = {
            total_sales: entry.total_sales,
            order_created_at: entry.order_created_at,
        };
        return map;
    }, {});

    // Fill missing weeks
    const filledSales = allWeeks.map((week) => {
        if (weekMap[week]) {
            return {
                week_of_month: week,
                total_sales: weekMap[week].total_sales,
                order_created_at: weekMap[week].order_created_at,
            };
        }

        // Calculate default date for missing weeks
        const placeholderDate = new Date(year, month, (week - 1) * 7 + 1); // Approximates the start date of the week

        return {
            week_of_month: week,
            total_sales: 0,
            order_created_at: placeholderDate.toISOString(), // Use ISO format for consistency
        };
    });

    // Sort by week_of_month
    filledSales.sort((a, b) => a.week_of_month - b.week_of_month);

    return filledSales;
};

const InventoryABCGradeMiniWidget = (id, data, currency) => {
    const chartsContainer = document.getElementById(id);

    if (!chartsContainer) return;

    if (!data || typeof data !== 'object') {
        console.log('No data available to display.');
        return;
    }

    let salesData;

    try {
        salesData = data; // Parse the JSON string
    } catch (error) {
        console.error("Invalid JSON data provided:", error);
        return;
    }

    // Validate salesData structure
    if (!salesData?.prev_month_sales?.weekly_sales || !salesData?.latest_month_sales?.weekly_sales) {
        console.error("Invalid sales data structure.");
        return;
    }

    // Determine the color settings based on `month_by_month_change`
    let latestMonthColorSettings = {};
    if (salesData.month_by_month_change < 0) {
        // Red color for negative growth
        latestMonthColorSettings = {
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.red[500])}, 0.08)`,
            borderColor: tailwindConfig().theme.colors.red[500],
            pointBackgroundColor: tailwindConfig().theme.colors.red[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.red[500],
        };
    } else if (salesData.month_by_month_change > 0) {
        // Green color for positive growth
        latestMonthColorSettings = {
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.green[500])}, 0.08)`,
            borderColor: tailwindConfig().theme.colors.green[500],
            pointBackgroundColor: tailwindConfig().theme.colors.green[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.green[500],
        };
    } else {
        // Default Indigo color for no change
        latestMonthColorSettings = {
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
            borderColor: tailwindConfig().theme.colors.indigo[500],
            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        };
    }

    // Process sales data and fill missing weeks with year-month context
    const filledPrevMonthSales = fillMissingWeeks(salesData.prev_month_sales.weekly_sales);
    const filledLatestMonthSales = fillMissingWeeks(salesData.latest_month_sales.weekly_sales);

    // Prepare Chart.js data
    const prevMonthSales = filledPrevMonthSales.map((entry) => ({
        x: entry.week_of_month,     // Use `week_of_month` for x-axis
        y: entry.total_sales,       // Sales amount
        order_created_at: entry.order_created_at, // Include `order_created_at` for tooltip
    }));

    const latestMonthSales = filledLatestMonthSales.map((entry) => ({
        x: entry.week_of_month,     // Use `week_of_month` for x-axis
        y: entry.total_sales,       // Sales amount
        order_created_at: entry.order_created_at, // Include `order_created_at` for tooltip
    }));

    // Destroy existing chart if it exists
    if (chartsContainer.chartInstance) {
        chartsContainer.chartInstance.destroy();
    }

    const chart = new Chart(chartsContainer, {
        type: 'line',
        data: {
            datasets: [
                // Dataset for previous month's weekly sales
                {
                    data: prevMonthSales,
                    borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.25)`,
                    borderWidth: 2,
                    tension: 0.2,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.25)`,
                    pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.25)`,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    clip: 20,
                },
                // Dataset for latest month's weekly sales with dynamic colors
                {
                    data: latestMonthSales,
                    fill: true,
                    backgroundColor: latestMonthColorSettings.backgroundColor,
                    borderColor: latestMonthColorSettings.borderColor,
                    borderWidth: 2,
                    tension: 0.2,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: latestMonthColorSettings.pointBackgroundColor,
                    pointHoverBackgroundColor: latestMonthColorSettings.pointHoverBackgroundColor,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    clip: 20,
                },
            ],
        },
        options: {
            chartArea: {
                backgroundColor: '#f8fafc', // Light background
            },
            layout: {
                padding: {
                    bottom: 25,
                }
            },
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                y: {
                    display: false,
                    beginAtZero: true,
                },
                x: {
                    type: 'time',
                    time: {
                        parser: 'YYYY-MM-DD',
                        unit: 'week',
                    },
                    display: false,
                    min: 1,
                    max: 5,
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => false, // Disable tooltip title
                        label: (context) => {
                            // Get the `order_created_at` for the current data point
                            const actualDate = context.raw.order_created_at;
                            const formattedDate = new Date(actualDate).toISOString().split('T')[0]; // Format date as YYYY-MM-DD

                            // Determine dataset label (Previous or Latest Month)
                            const datasetLabel = context.datasetIndex === 0 ? 'Previous Month' : 'Latest Month';

                            // Split the tooltip content into multiple lines
                            return [
                                `${formattedDate} (Week ${context.parsed.x}):`,  // First line
                                `${formatValue(context.parsed.y, currency)}`,
                            ];

                        },
                    },
                    bodyColor: '#1e293b', // Tooltip text color
                    backgroundColor: '#ffffff', // Tooltip background
                    borderColor: '#e2e8f0', // Tooltip border
                },
                legend: {
                    display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
        },
    });

    // Store the chart instance in the container for future reference
    chartsContainer.chartInstance = chart;
};

export default InventoryABCGradeMiniWidget;
