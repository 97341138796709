// Import Chart.js
import {
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Tooltip
} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';

// Import utilities
import {formatValue, hexToRGB, tailwindConfig} from '../utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip, Legend, Annotation);
// A chart built with Chart.js 3
// https://www.chartjs.org/
const insightSalesWidget = (id, forecastData, historyData, currency) => {
  const ctx = document.getElementById(id);
  if (!ctx) return;
  const widgetName = 'SalesWidget: ';

  const darkMode = localStorage.getItem('dark-mode') === 'true';

  const textColor = {
    light: '#94a3b8',
    dark: '#64748B'
  };

  const gridColor = {
    light: '#f1f5f9',
    dark: '#334155'
  };

  const tooltipBodyColor = {
    light: '#1e293b',
    dark: '#f1f5f9'
  };

  const tooltipBgColor = {
    light: '#ffffff',
    dark: '#334155'
  };

  const tooltipBorderColor = {
    light: '#e2e8f0',
    dark: '#475569'
  };

    // Check if forecastData is null, empty, or invalid
    if (!forecastData || forecastData === "null" || forecastData === "") {
        console.log(widgetName + ' No forecast data available to display.');
        return; // Exit if no forecast data
    }

    // Check if forecastData has the necessary properties
    if (!forecastData.yhat || !forecastData.yhat_lower || !forecastData.yhat_upper || !forecastData.ds) {
        console.error(widgetName + " Forecast data is missing required properties (ds, yhat, yhat_lower, yhat_upper).");
        return; // Exit if required properties are missing
    }

    // Check if historyData is present and has the necessary properties
    if (!historyData || !historyData.data || !historyData.columns.includes('ds') || !historyData.columns.includes('y')) {
        console.error(widgetName + " History data is missing or has incomplete properties (ds, y).");
        return; // Exit if required history data is missing
    }

    // Extract values from forecastData
    const forecastDates = Object.values(forecastData.ds); // Extracting the 'ds' values.
    const predictions = Object.values(forecastData.yhat); // Extracting the 'yhat' values.
    const lowerBounds = Object.values(forecastData.yhat_lower); // Extracting the 'yhat_lower' values.
    const upperBounds = Object.values(forecastData.yhat_upper); // Extracting the 'yhat_upper' values;

    // Extract values from historyData
    const dsIndex = historyData.columns.indexOf('ds');  // Find index of 'ds' column
    const yIndex = historyData.columns.indexOf('y');    // Find index of 'y' column

    const historyDates = historyData.data.map(row => row[dsIndex]); // Extracting 'ds' values from historyData.
    const historySales = historyData.data.map(row => row[yIndex]);  // Extracting 'y' (sales) values from historyData.

// Select the chart canvas and set dynamic height
    ctx.style.height = `${window.innerWidth < 768 ? 200 : 300}px`;

    const chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [...historyDates, ...forecastDates],
          datasets: [
              {
                  label: 'Past Sales',
                  data: historySales,
                  borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[600])}, 0.25)`,
                  backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[600])}, 0.25)`,
                  fill: false,
                  borderWidth: 2,
                  tension: 0.2,
                  pointRadius: window.innerWidth < 768 ? 0 : 0, // Smaller points on mobile
                  pointHoverRadius: window.innerWidth < 768 ? 0 : 3,
                  pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[600])}, 0.25)`,
              },
              {
                  label: 'Forecast',
                  data: [...Array(historySales.length).fill(null), ...predictions],
                  borderColor: tailwindConfig().theme.colors.indigo[500],
                  backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                  borderWidth: 2,
                  tension: 0.2,
                  pointRadius: window.innerWidth < 768 ? 0 : 0,
                  pointHoverRadius: window.innerWidth < 768 ? 0 : 3,
                  pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
              },
                {
                    label: 'Optimistic',
                    data: [...Array(historySales.length).fill(null), ...upperBounds],
                    borderColor: 'rgba(54, 162, 235, 0.2)',
                    borderDash: [5, 5], // Dashed line
                    backgroundColor: 'rgba(54, 162, 235, 0.4)', // Slightly more opaque
                    fill: false,
                    tension: 0.2,
                    pointRadius: window.innerWidth < 768 ? 0 : 0,
                    pointHoverRadius: 3,
                },
                {
                    label: 'Pessimistic',
                    data: [...Array(historySales.length).fill(null), ...lowerBounds],
                    borderColor: 'rgba(255, 99, 132, 0.2)',
                    borderDash: [5, 5], // Dashed line
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: false,
                    tension: 0.2,
                    pointRadius: window.innerWidth < 768 ? 0 : 0,
                    pointHoverRadius: 3,
                }
          ],
        },
        options: {
            layout: {
                padding: window.innerWidth < 768 ? 10 : 20, // Smaller padding on mobile
            },
          scales: {
            y: {
              beginAtZero: false,
              border: {
                display: true,
              },
              ticks: {
                maxTicksLimit: window.innerWidth < 768 ? 2 : 5, // Fewer ticks on mobile
                  callback: (value) => {
                      if (value >= 1000) {
                          return `${formatValue(value / 1000, currency)}K`; // Convert to 'K' format
                      }
                      return `${value}`; // Leave values less than 1000 as they are
                  },
                  color: darkMode ? textColor.dark : textColor.light,
                padding: 10,
              },
              grid: {
                color: darkMode ? gridColor.dark : gridColor.light,
              },
            },
            x: {
              type: 'time',
              time: {
                // parser: 'MM-DD-YYYY',
                unit: 'week',
                displayFormats: {
                  month: 'MMM YY',
                },
              },
                title: {
                    display: false,
                    text: 'Date'
                },
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
              ticks: {
                maxTicksLimit: window.innerWidth < 768 ? 4 : 10, // Adjust ticks for mobile
                autoSkipPadding: 24, // Reduce auto-skip padding
                maxRotation: 0,
                color: darkMode ? textColor.dark : textColor.light,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            htmlLegend: {
              // ID of the container to put the legend in
              containerID: 'insight-sales-widget-legend',
            },
            tooltip: {
              callbacks: {
                title: () => false, // Disable tooltip title
                  label: function(context) {
                      const label = context.dataset.label || '';
                      const value = context.parsed.y.toLocaleString();
                      const date = new Date(context.label).toLocaleDateString('en-US', {
                          month: 'short', // "short" or "long" for month name
                          day: '2-digit',
                          year: 'numeric'
                      });
                      return `${label}: $${value} on ${date}`;
                  }
              },
              bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
              backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
              borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
            },
            annotation: {
              annotations: {
                  todayLine: {
                      type: 'line',
                      xMin: new Date().toISOString().split('T')[0], // Today's date
                      xMax: new Date().toISOString().split('T')[0], // Same as xMin, for a vertical line
                      borderColor: 'gray',
                      borderWidth: 1,
                      borderDash: [5, 5], // Dashed line
                      label: {
                          adjustScaleRange: true, // Adjust the scale to fit the label
                          content: 'Today',
                          display: true,
                          enabled: true,
                          position: 'end', // Positions the label at the end (top for vertical lines)
                          backgroundColor: '#fff', // Optional: adds background color to label
                          color: 'gray', // Text color
                          yAdjust: -10, // Adjusts the label position along y-axis
                          rotation: 0,
                      }
                  }
              }
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          responsive: true,
          maintainAspectRatio: false,
        },
        plugins: [{
          id: 'htmlLegend',
          afterUpdate(c, args, options) {
            const legendContainer = document.getElementById(options.containerID);
            const ul = legendContainer.querySelector('ul');
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins.legend.labels.generateLabels(c);
            items.forEach((item) => {
              const li = document.createElement('li');
              li.style.marginLeft = tailwindConfig().theme.margin[3];
              // Button element
              const button = document.createElement('button');
              button.style.display = 'inline-flex';
              button.style.alignItems = 'center';
              button.style.opacity = item.hidden ? '.3' : '';
              button.onclick = () => {
                c.setDatasetVisibility(item.datasetIndex, !c.isDatasetVisible(item.datasetIndex));
                c.update();
              };
              // Color box
              const box = document.createElement('span');
              box.style.display = 'block';
              box.style.width = tailwindConfig().theme.width[3];
              box.style.height = tailwindConfig().theme.height[3];
              box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
              box.style.marginRight = tailwindConfig().theme.margin[2];
              box.style.borderWidth = '3px';
              box.style.borderColor = c.data.datasets[item.datasetIndex].borderColor;
              box.style.pointerEvents = 'none';
              // Label
              const label = document.createElement('span');
              label.classList.add('text-slate-500', 'dark:text-slate-400');
              label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
              label.style.lineHeight = tailwindConfig().theme.fontSize.sm[1].lineHeight;
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        }],
      });

      document.addEventListener('darkMode', (e) => {
        const { mode } = e.detail;
        if (mode === 'on') {
          chart.options.scales.x.ticks.color = textColor.dark;
          chart.options.scales.y.ticks.color = textColor.dark;
          chart.options.scales.y.grid.color = gridColor.dark;
          chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.dark;
          chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.dark;
          chart.options.plugins.tooltip.borderColor = tooltipBorderColor.dark;
        } else {
          chart.options.scales.x.ticks.color = textColor.light;
          chart.options.scales.y.ticks.color = textColor.light;
          chart.options.scales.y.grid.color = gridColor.light;
          chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
          chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
          chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;
        }
        chart.update('none');
      });
};

export default insightSalesWidget;
